import React from "react";
import Conclusion from "../../components/project/Conclusion";
import CommonProjectComponent from "../../components/project/CommonProjectComponent";
import ProblemSolution from "../../components/project/ProblemSolution";
import ProjectDetailBanner from "../../components/project/ProjectDetailBanner";
import PreviousNext from "../../components/PreviousNext";
import { Seo } from "../../components/seo";
import Layout from "../../components/layout";

const Stride = () => {
  const headingbanner = {
    title: `Stride`,
    content: `A Blood Bank Management Platform`,
  };

  const data = {
    images: ["stride.webp", "stride-2.webp"],
    firstAlt: "All Donor's List",
    firstTitle: "Donor's List",
    secondAlt: "UI Screen Of Donor Details",
    secondTitle: "Donor Details",
  };

  const problemSolutionContent = {
    sidebar: [
      {
        title: "Industry",
        text: ["HealthTech"],
      },
      {
        title: "What we did",
        text: ["Digital Transformation", "UI UX Designing", "Usability Testing"],
      },
      {
        title: "Platform",
        text: ["Web Application"],
      },
    ],
    mainContent: [
      {
        title: "Problem",
        content: [
          `There were multiple complex systems and procedures that were involved into functioning of blood bank which resulted in reduced efficiency. `,
          `These procedures increased the scope of errors, inaccuracy in critical tasks and increased user frustration.`,
        ],
      },
      {
        title: "Solution",
        content: [
          `Our research on the existing platform, different workflows, use cases revealed a significant scope of improving the platforms' structure. `,
          `We aimed at refining the critical flows by designing systematic layouts and better representation of the data which would give a holistic experience to the user leading to increased efficiency.`,
        ],
      },
    ],
    image: ["stride-problem.webp"],
    firstAlt: 'All Mockup Screens',
    firstTitle: 'Project Screens',
  };

  const projectData = {
    title: `Register Donors & Maintain Records`,
    para: [
      `Donor registration was one of the important flows of the platform. A structured grid format made the complex task of managing and scanning donor's record effortless and also assisted in easy tracking of the donation status. `,
    ],
    content: {
      imageAlt: "All Donor's List",
      imageTitle: "Donor's List",
      image: ["stride-projectone.webp"],
      text: `Recording and collecting the data required a streamlined flow, hence we divided the process into different steps for focused user attention.`,
    },
    content2: {
      imageAlt: "In-house counselling details of the donors",
      imageTitle: "Medical Counselling Details",
      image: ["stride-projectone-2.webp"],
      text: `We also created an online medical counselling interface to check the health status of the donor to with see if they are eligible to donate blood and the time after which they can donate blood.`,
    },
  };

  const projectData2 = {
    title: `Run Operations Seamlessly `,
    para: [
      `Systematic representation of required information made the screens more scannable. Users could switch between different tabs and pages effortlessly that led to increased efficiency and less errors.`,
    ],
    content: {
      imageAlt: "UI screen of component processing and test details",
      imageTitle: "Components Processing",
      image: ["stride-projecttwo.webp"],
    },
  };

  const projectData3 = {
    title: `Streamline The Donation Process`,
    para: [
      `We simplified and optimised design flows keeping in mind the users need to enter the details and manage tasks accurately.`,
    ],
    content: {
      image: [
        "stride-projectthree.webp",
        "stride-projectthree-2.webp",
        "stride-projectthree-3.webp",
        "stride-projectthree-4.webp",
        "stride-projectthree-5.webp",
        "stride-projectthree-6.webp",
      ],
      firstAlt: 'In-house Donation Application of the Donors',
      firstTitle: 'Delayed Branching',
      secondAlt: 'UI Screen of Blood Grouping Details',
      secondTitle: 'Blood Group Details',
      thirdAlt: 'Blood Donation Camp Details',
      thirdTitle: 'Camp Details',
      fourthAlt: 'Blood Donation Request Details',
      fourthTitle: 'Request Details',
      fifthAlt: 'Crossmatch Billing details',
      fifthTitle: 'Billing Details',
      sixthAlt: 'UI Screen to Generate Blood Donor IDs',
      sixthTitle: 'Generate Donor IDs',
    },
  };

  const conclusionData = [
    {
      para: [
        `Our efforts to address the challenges faced by the blood bank management platform have yielded promising results.`,
        `By carefully studying the existing system, workflows, and use cases, we identified areas for improvement and set out to streamline and enhance its structure.`,
        `Through meticulous design and implementation, we have succeeded in refining the critical flows within the platform. `,
        `By creating systematic layouts and improving data representation, we have delivered a more user-friendly experience to our stakeholders.`,
        `As a result of our efforts, the blood bank management platform now offers a holistic and seamless experience for its users.`
      ],
    },
  ];

  return (
    <Layout>
      <div className="service-page">
        <ProjectDetailBanner data={data} headingbanner={headingbanner} />
        <ProblemSolution data={problemSolutionContent} />
        <CommonProjectComponent data={projectData} mode="cream" />
        <CommonProjectComponent data={projectData2} />
        <CommonProjectComponent data={projectData3} mode="dark" />
        <Conclusion conclusionData={conclusionData} />
        <PreviousNext currentProjectId={3} />
      </div>
    </Layout>
  );
};

export default Stride;

export const Head = () => (
  <Seo title="Stride- Blood Bank Management Platform Case Study | Octet" description="We transfomed a Web App platform offering blood bank management solutions and offered UI UX Designing and Usability Testing Services." />
)